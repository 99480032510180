import React, { useRef, useMemo, useState, useEffect, Suspense } from "react"
import * as THREE from "three"
import {
  Canvas,
  useFrame,
  extend,
  useThree,
  useLoader,
} from "react-three-fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import Color from "color"
import { CompactPicker } from "react-color"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { Fragment } from "react"

extend({ OrbitControls })

const getSleepTimes = frameTime => {
  const ticks = 2040
  const timePerTick = frameTime / ticks
  return [
    0b00000001,
    0b00000010,
    0b00000100,
    0b00001000,
    0b00010000,
    0b00100000,
    0b01000000,
    0b10000000,
  ].map(i => timePerTick * i)
}

const getColorForBitAngle = (baseColor, bitAngle) => {
  const color = Color(baseColor)
  const [r, g, b] = color.rgb().array()
  const mask = 0b00000001 << bitAngle
  return Color.rgb(
    r & mask ? 255 : 0,
    g & mask ? 255 : 0,
    b & mask ? 255 : 0
  ).hex()
}

// https://codeworkshop.dev/blog/2020-04-03-adding-orbit-controls-to-react-three-fiber/
function Controls(props) {
  const controls = useRef()
  const {
    camera,
    gl: { domElement },
  } = useThree()
  useFrame(state => controls.current.update())
  return <orbitControls ref={controls} args={[camera, domElement]} {...props} />
}

const tempObject = new THREE.Object3D()
const tempColor = new THREE.Color()
const blackColor = new THREE.Color(0, 0, 0)

const useBitAngleDemo = (
  cube,
  colorArray,
  { frameTime, color, bitAngleCallback = () => {} }
) => {
  const sleepUntil = useRef(0)
  const currentBitPos = useRef(0)
  const currentLayer = useRef(0)
  const skipAngle = () => {
    currentLayer.current = 0
    currentBitPos.current++
    bitAngleCallback(currentBitPos.current)
    if (currentBitPos.current === 8) {
      currentBitPos.current = 0
    }
  }

  const sleepTimes = useMemo(() => {
    return getSleepTimes(frameTime)
  }, [frameTime])

  const getSleepTimeForBitAngle = bitAngle => {
    return sleepTimes[bitAngle]
  }

  const canUseBitAngle = () => {
    return sleepTimes.some(time => time > 1 / 60)
  }

  useFrame(state => {
    if (canUseBitAngle()) {
      const time = state.clock.getElapsedTime()
      if (sleepUntil.current && time < sleepUntil.current) {
        return
      }

      if (currentLayer.current < 8) {
        const sleepTime = getSleepTimeForBitAngle(currentBitPos.current)
        if (sleepTime < 1 / 60) {
          skipAngle()
          return
        }
        let i = 0
        for (let x = 0; x < 8; x++) {
          for (let y = 0; y < 8; y++) {
            for (let z = 0; z < 8; z++) {
              const id = i++
              if (y === currentLayer.current) {
                tempColor
                  .set(getColorForBitAngle(color, currentBitPos.current))
                  .toArray(colorArray, id * 3)
              } else {
                blackColor.toArray(colorArray, id * 3)
              }
            }
          }
        }
        cube.geometry.attributes.color.needsUpdate = true
        sleepUntil.current = time + sleepTime
        currentLayer.current++
      } else {
        skipAngle()
      }
    } else {
      let i = 0
      for (let x = 0; x < 8; x++) {
        for (let y = 0; y < 8; y++) {
          for (let z = 0; z < 8; z++) {
            const id = i++
            tempColor.set(color).toArray(colorArray, id * 3)
          }
        }
      }
      cube.geometry.attributes.color.needsUpdate = true
    }
  })
}

const url = "/led.gltf"
function LED({ colorArray }) {
  const gltf = useLoader(GLTFLoader, url)
  return (
    <primitive attach="geometry" object={gltf.scene.children[2].geometry}>
      <instancedBufferAttribute
        attachObject={["attributes", "color"]}
        args={[colorArray, 3]}
      />
    </primitive>
  )
}

// https://codesandbox.io/s/r3f-instanced-colors-8fo01?from-embed=&file=/src/index.js
function Cube({ color, frameTime, bitAngleCallback }) {
  const ref = useRef()
  useLoader(GLTFLoader, url)

  const colorArray = useMemo(
    () =>
      Float32Array.from(
        new Array(512).fill(0).flatMap((_, i) => tempColor.set(color).toArray())
      ),
    [color]
  )

  useBitAngleDemo(ref.current, colorArray, {
    frameTime,
    color,
    bitAngleCallback,
  })

  useEffect(() => {
    let i = 0
    for (let x = 0; x < 8; x++)
      for (let y = 0; y < 8; y++)
        for (let z = 0; z < 8; z++) {
          const id = i++
          tempObject.position.set(x, y, z)
          tempObject.rotation.set(Math.PI / 2, 0, 0)
          tempObject.scale.set(0.05, 0.05, 0.05)
          tempObject.updateMatrix()
          ref.current.setMatrixAt(id, tempObject.matrix)
        }
    ref.current.instanceMatrix.needsUpdate = true
  }, [])

  const [_, _setPhongMat] = useState()

  const setPhongMat = phongMat => {
    if (!phongMat) {
      return
    }
    phongMat.onBeforeCompile = shader => {
      shader.fragmentShader = shader.fragmentShader.replace(
        "vec3 totalEmissiveRadiance = emissive;",
        ["vec3 totalEmissiveRadiance = vColor;"].join("\n")
      )
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <color_fragment>",
        [
          "if (vColor.rgb[0] == 0. && vColor.rgb[1] == 0. && vColor.rgb[2] == 0.) { diffuseColor = vec4(.1, .1, .1, .2); } else { diffuseColor = vec4(0, 0, 0, .9); }",
          "",
        ].join("\n")
      )

      phongMat.userData.shader = shader
    }
    _setPhongMat(phongMat)
  }

  return (
    <instancedMesh ref={ref} count={512} args={[null, null, 512]}>
      <LED colorArray={colorArray} />
      <meshPhongMaterial
        ref={setPhongMat}
        attach="material"
        vertexColors
        shininess={5}
        transparent
      />
    </instancedMesh>
  )
}

const CubeCanvas = props => {
  const [color, setColor] = useState("#AB149E")
  const [frameTime, setFrameTime] = useState(10)
  const [currentBitAngle, setCurrentBitAngle] = useState(0)
  const [r, g, b] = useMemo(() => Color(color).rgb().array(), [color])
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <div css={{ height: 400 }}>
        <Canvas
          gl={{ antialias: false, alpha: false }}
          camera={{ position: [8, 8, 12], near: 0.1, far: 2000 }}
          onCreated={({ gl, camera }) => {
            gl.setClearColor("#ffffff")
          }}
        >
          <Controls />
          <spotLight color="#ffffff" intensity={0.5} position={[0, 0, 20]} />
          <Suspense fallback={<ambientLight color="#ffffff" intensity={2} />}>
            <Cube
              color={color}
              frameTime={frameTime}
              bitAngleCallback={setCurrentBitAngle}
            />
          </Suspense>
        </Canvas>
      </div>

      <div
        css={{
          margin: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          css={{
            display: "flex",
            marginBottom: 20,
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <CompactPicker
            css={{
              marginRight: 20,
            }}
            color={color}
            onChangeComplete={color => setColor(color.hex)}
            colors={[
              "#FF0000",
              "#00FF00",
              "#0000FF",
              "#F44E3B",
              "#FE9200",
              "#FCDC00",
              "#DBDF00",
              "#A4DD00",
              "#68CCCA",
              "#73D8FF",
              "#AEA1FF",
              "#FDA1FF",
              "#D33115",
              "#E27300",
              "#FCC400",
              "#B0BC00",
              "#68BC00",
              "#16A5A5",
              "#009CE0",
              "#7B64FF",
              "#FA28FF",
              "#9F0500",
              "#C45100",
              "#FB9E00",
              "#808900",
              "#194D33",
              "#0C797D",
              "#0062B1",
              "#653294",
              "#AB149E",
            ]}
          />
          <div
            css={{ display: "flex", flexDirection: "column" }}
          >
            <div>
              <input
                type="radio"
                value="10"
                onChange={e => setFrameTime(parseFloat(e.target.value))}
                checked={frameTime.toString() === "10"}
              />{" "}
              Slow
            </div>
            <div>
              <input
                type="radio"
                value="5"
                onChange={e => setFrameTime(parseFloat(e.target.value))}
                checked={frameTime.toString() === "5"}
              />{" "}
              Medium
            </div>
            <div>
              <input
                type="radio"
                value="0.1"
                onChange={e => setFrameTime(parseFloat(e.target.value))}
                checked={frameTime.toString() === "0.1"}
              />{" "}
              Realtime
            </div>
          </div>
        </div>
        <div css={{ alignSelf: "stretch" }}>
          <table
            css={{
              fontSize: ".75rem",
              "& td": {
                padding: 0,
              },
            }}
          >
            <thead>
              <tr>
                <td>Bit #</td>
                <td>R</td>
                <td>G</td>
                <td>B</td>
                <td>Output</td>
              </tr>
            </thead>
            <tbody>
              {[...Array(8)].map((_, bitAngle) => {
                return (
                  <tr key={bitAngle}>
                    <td>
                      {currentBitAngle === bitAngle && frameTime !== 0.1
                        ? `${bitAngle}✔️`
                        : bitAngle}
                    </td>
                    {[r, g, b].map(color => {
                      return (
                        <td key={color}>
                          {[...color.toString(2).padStart(8, "0")].map(
                            (digit, i) => {
                              if (7 - i === bitAngle) {
                                return (
                                  <span key={i} css={{ textDecoration: "underline" }}>
                                    {digit}
                                  </span>
                                )
                              } else {
                                return <Fragment key={i}>{digit}</Fragment>
                              }
                            }
                          )}
                        </td>
                      )
                    })}
                    <td>
                      <div
                        css={{
                          height: 10,
                          width: 10,
                          backgroundColor: getColorForBitAngle(color, bitAngle),
                        }}
                      ></div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CubeCanvas
